import antdID from "antd/lib/locale-provider/id_ID";
import saMessages from "../locales/id_ID.json";

const idLang = {
  messages: {
    ...saMessages,
  },
  antd: antdID,
  locale: "id-ID",
};

export default idLang;
