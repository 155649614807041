import * as actionTypes from "./navigationTypes";

const INITIAL_STATE = {
  navigation_id: false,
};

const navigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.NAVIGATION_ID:
      return {
        ...state,
        navigation_id: action.payload,
      };

    default:
      return state;
  }
};

export default navigationReducer;
