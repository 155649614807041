import React from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Menu } from "antd";

import navigation from "../../../../navigation/vertical";

const { SubMenu } = Menu;

export default function MenuItem(props) {
  const { onClose } = props;

  // Redux
  const customise = useSelector((state) => state.customise);

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  // console.log(window.localStorage.getItem("menu"));

  let management = window.localStorage.getItem("menu")
    ? JSON.parse(window.localStorage.getItem("menu"))
    : {};

  const menuItem = navigation.map((item, index) => {
    let check = false;

    check = item.children
      ? item.children.filter((el) => {
          return (
            (management.role_management
              ? management.role_management
              : []
            ).filter((els) => {
              if (el.title === els.menu) {
                if (new RegExp("R").test(els.access)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            }).length > 0
          );
        }).length > 0
      : false;

    if (item.title === "Employee") {
      check = (
        management.role_management ? management.role_management : []
      ).find((el) => el.menu === item.title && new RegExp("R").test(el.access));
    }
    if (item.title === "Food Coupon") {
      check = (
        management.role_management ? management.role_management : []
      ).find((el) => el.menu === item.title && new RegExp("R").test(el.access));
    }
    if (item.title === "Setting") {
      check = false;
    }

    if (item.header) {
      return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
    }

    if (item.child) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((childrens, index) => {
            if (!childrens.children) {
              const childrenNavLink = childrens.navLink.split("/");
              return (
                // Level 2
                <Menu.Item
                  key={childrens.id}
                  className={
                    splitLocationUrl ===
                    childrenNavLink[childrenNavLink.length - 2] +
                      "/" +
                      childrenNavLink[childrenNavLink.length - 1]
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                  onClick={onClose}
                >
                  <Link to={childrens.navLink}>{childrens.title}</Link>
                </Menu.Item>
              );
            } else {
              return (
                // Level 3
                <SubMenu key={childrens.id} title={childrens.title}>
                  {childrens.children.map((childItem, index) => {
                    const childrenItemLink = childItem.navLink.split("/");

                    return (
                      <Menu.Item
                        key={childItem.id}
                        className={
                          splitLocationUrl ===
                          childrenItemLink[childrenItemLink.length - 2] +
                            "/" +
                            childrenItemLink[childrenItemLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                        }
                        onClick={onClose}
                      >
                        <Link to={childItem.navLink}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    } else {
      const itemNavLink = item.navLink.split("/");
      if (typeof item.show !== "undefined") {
        if (item.show) {
          if (check) {
            return (
              // Level 1
              <Menu.Item
                key={item.id}
                icon={item.icon}
                onClick={onClose}
                className={
                  splitLocation[splitLocation.length - 2] +
                    "/" +
                    splitLocation[splitLocation.length - 1] ===
                  itemNavLink[itemNavLink.length - 2] +
                    "/" +
                    itemNavLink[itemNavLink.length - 1]
                    ? "ant-menu-item-selected"
                    : "ant-menu-item-selected-in-active"
                }
              >
                <Link to={item.navLink}>{item.title}</Link>
              </Menu.Item>
            );
          }
        } else {
          return null;
        }
      }
      if (check) {
        return (
          // Level 1
          <Menu.Item
            key={item.id}
            icon={item.icon}
            onClick={onClose}
            className={
              splitLocation[splitLocation.length - 2] +
                "/" +
                splitLocation[splitLocation.length - 1] ===
              itemNavLink[itemNavLink.length - 2] +
                "/" +
                itemNavLink[itemNavLink.length - 1]
                ? "ant-menu-item-selected"
                : "ant-menu-item-selected-in-active"
            }
          >
            <Link to={item.navLink}>{item.title}</Link>
          </Menu.Item>
        );
      } else {
        return null;
      }
    }
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5
          ? splitLocation[splitLocation.length - 3]
          : null,
        splitLocation[splitLocation.length - 2],
      ]}
      theme={customise.theme === "light" ? "light" : "dark"}
    >
      {menuItem}
    </Menu>
  );
}
