import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/error-page",
    component: lazy(() => import("../../view/pages/error")),
    layout: "FullLayout",
  },
  {
    path: "/pages/employee",
    component: lazy(() => import("../../view/pages/employee")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/pages/employee-transfer",
    component: lazy(() => import("../../view/pages/employee/employeeTransfer")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/employee-turn-off",
    component: lazy(() => import("../../view/pages/employee/employeeTurnOff")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/reprimand",
    component: lazy(() => import("../../view/pages/employee/reprimand")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/recruitment",
    component: lazy(() => import("../../view/pages/employee/recruitment")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/coupon-lunch",
    component: lazy(() => import("../../view/pages/employee/couponLunch")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/add-employee",
    component: lazy(() => import("../../view/pages/employee/addEmployee")),
    layout: "FullLayout",
  },
  {
    path: "/pages/detail-employee",
    component: lazy(() => import("../../view/pages/employee/detailEmployee")),
    layout: "FullLayout",
  },
  {
    path: "/pages/employee-agreement-contract",
    component: lazy(() =>
      import("../../view/pages/employee/agreementContract")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/pages/employee-agreement-contract/detail",
    component: lazy(() =>
      import(
        "../../view/pages/employee/agreementContract/detailAgreementContract"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/reminder",
    component: lazy(() => import("../../view/pages/reminder")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/reminder/request-time-off",
    component: lazy(() => import("../../view/pages/request-time-out")),
    layout: "VerticalLayout",
  },
  {
    path: "/reminder/request-overtime",
    component: lazy(() => import("../../view/pages/request-overtime")),
    layout: "VerticalLayout",
  },
  {
    path: "/reminder/request-change-of-shift",
    component: lazy(() => import("../../view/pages/request-change-shift")),
    layout: "VerticalLayout",
  },
  {
    path: "/reminder/request-change-profile",
    component: lazy(() => import("../../view/pages/request-change-profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/reminder/request-assessment-management",
    component: lazy(() =>
      import("../../view/pages/request-assessment-management")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },
  {
    path: "/register",
    component: lazy(() => import("../../view/pages/authentication/register")),
    layout: "FullLayout",
  },
  {
    path: "/forgot-password",
    component: lazy(() =>
      import("../../view/pages/authentication/recover-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/reset-password",
    component: lazy(() =>
      import("../../view/pages/authentication/reset-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/attendance",
    component: lazy(() => import("../../view/pages/attendance/dashboard")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/attendance/dashboard-detail",
    component: lazy(() =>
      import("../../view/pages/attendance/dashboard/dashboard-detail")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/attendance/report-attendance",
    component: lazy(() =>
      import("../../view/pages/attendance/report-attendance")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/attendance/report-attendance/detail",
    component: lazy(() =>
      import(
        "../../view/pages/attendance/report-attendance/detail-report-attendance"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/attendance/report-overtime",
    component: lazy(() =>
      import("../../view/pages/attendance/report-overtime")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/attendance/report-overtime/detail",
    component: lazy(() =>
      import(
        "../../view/pages/attendance/report-overtime/detail-report-overtime"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/attendance/time-off-deduction",
    component: lazy(() =>
      import("../../view/pages/attendance/time-off-deduction")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/attendance/time-off-deduction/detail",
    component: lazy(() =>
      import(
        "../../view/pages/attendance/time-off-deduction/detail-time-off-deduction"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/attendance/approval-time-off",
    component: lazy(() =>
      import("../../view/pages/attendance/approval-time-off")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll",
    component: lazy(() => import("../../view/pages/payroll/master-payroll")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/payroll/update-component",
    component: lazy(() =>
      import("../../view/pages/payroll/master-payroll/update-component")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/update-component-employee",
    component: lazy(() =>
      import(
        "../../view/pages/payroll/master-payroll/update-component-employee"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/detail-update-component-employee",
    component: lazy(() =>
      import(
        "../../view/pages/payroll/master-payroll/detail-update-component-employee"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/run-payroll",
    component: lazy(() => import("../../view/pages/payroll/run-payroll")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/payroll/detail-run-payroll",
    component: lazy(() =>
      import("../../view/pages/payroll/run-payroll/detail")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/run-thr",
    component: lazy(() => import("../../view/pages/payroll/run-THR")),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/detail-run-thr",
    component: lazy(() => import("../../view/pages/payroll/run-THR/detail")),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/report",
    component: lazy(() => import("../../view/pages/payroll/report-payroll")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/payroll/report/salary-detail",
    component: lazy(() =>
      import("../../view/pages/payroll/report-payroll/salary-detail")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/report/report-payroll-to-finance",
    component: lazy(() =>
      import(
        "../../view/pages/payroll/report-payroll/report-payroll-to-finance"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/report/summary-report-by-department",
    component: lazy(() =>
      import(
        "../../view/pages/payroll/report-payroll/summary-report-by-department"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/report/thr-report",
    component: lazy(() =>
      import("../../view/pages/payroll/report-payroll/thr-report")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/report/pay-slip",
    component: lazy(() =>
      import("../../view/pages/payroll/report-payroll/pay-slip")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/report/thr-slip",
    component: lazy(() =>
      import("../../view/pages/payroll/report-payroll/thr-slip")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management",
    component: lazy(() =>
      import("../../view/pages/timeManagement/masterTimeOff")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/payroll/setting/pro-rate-settings",
    component: lazy(() => import("../../view/pages/payroll/setting/pro-rate")),
    layout: "VerticalLayout",
  },
  {
    path: "/payroll/setting/thr",
    component: lazy(() => import("../../view/pages/payroll/setting/thr")),
    layout: "VerticalLayout",
  },
  // {
  //   path: "/payroll/setting/absence",
  //   component: lazy(() => import("../../view/pages/payroll/setting/absence")),
  //   layout: "VerticalLayout",
  // },
  {
    path: "/payroll/setting/cut-off",
    component: lazy(() => import("../../view/pages/payroll/setting/cut-off")),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/absence",
    component: lazy(() =>
      import("../../view/pages/timeManagement/masterAbsence")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/national-holiday",
    component: lazy(() =>
      import("../../view/pages/timeManagement/masterNationalHoliday")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/schedule",
    component: lazy(() =>
      import("../../view/pages/timeManagement/masterSchedule/schedule")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/add-schedule",
    component: lazy(() =>
      import(
        "../../view/pages/timeManagement/masterSchedule/schedule/addSchedule"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/edit-schedule",
    component: lazy(() =>
      import(
        "../../view/pages/timeManagement/masterSchedule/schedule/editSchedule"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/attendance-machine",
    component: lazy(() =>
      import("../../view/pages/timeManagement/masterSchedule/attendanceMachine")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/time-management/setting",
    component: lazy(() => import("../../view/pages/timeManagement/setting")),
    layout: "VerticalLayout",
  },
  //training
  {
    path: "/training",
    component: lazy(() => import("../../view/pages/training/dashboard")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/training/schedule",
    component: lazy(() => import("../../view/pages/training/schedule")),
    layout: "VerticalLayout",
  },
  {
    path: "/training/set-schedule",
    component: lazy(() =>
      import("../../view/pages/training/schedule/set-schedule")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/training/course",
    component: lazy(() => import("../../view/pages/training/course")),
    layout: "VerticalLayout",
  },
  {
    path: "/training/detail-course",
    component: lazy(() =>
      import("../../view/pages/training/course/detail-course")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/training/detail-exam",
    component: lazy(() =>
      import("../../view/pages/training/course/detail-exam")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/training/report-of-quiz",
    component: lazy(() => import("../../view/pages/training/reportOfQuiz")),
    layout: "VerticalLayout",
  },

  // food coupon
  {
    path: "/food-coupon",
    component: lazy(() => import("../../view/pages/food-coupon")),
    layout: "VerticalLayout",
    exact: true,
  },

  //matrix kpi
  {
    path: "/matrix-kpi",
    component: lazy(() => import("../../view/pages/matrix-kpi/dashboard")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/matrix-kpi/kpi-indicator",
    component: lazy(() => import("../../view/pages/matrix-kpi/kpi-indicator")),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/matrix-kpi/assesment-hr",
    component: lazy(() =>
      import("../../view/pages/matrix-kpi/assesment/assesment-hr")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/matrix-kpi/assesment-spv",
    component: lazy(() =>
      import("../../view/pages/matrix-kpi/assesment/assesment-spv")
    ),
    layout: "VerticalLayout",
  },
  // {
  //   path: "/matrix-kpi/assesment-management",
  //   component: lazy(() =>
  //     import("../../view/pages/matrix-kpi/assesment/assesment-management")
  //   ),
  //   layout: "VerticalLayout",
  // },
  {
    path: "/matrix-kpi/result",
    component: lazy(() => import("../../view/pages/matrix-kpi/result")),
    layout: "VerticalLayout",
  },

  // Settings
  {
    path: "/settings/account-preferences",
    component: lazy(() =>
      import("../../view/pages/settings/account-preferences")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/settings/change-password",
    component: lazy(() =>
      import("../../view/pages/settings/account-preferences/change-password")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/company",
    component: lazy(() =>
      import("../../view/pages/settings/company/company-info")
    ),
    layout: "VerticalLayout",
    exact: true,
  },
  {
    path: "/settings/branch-list",
    component: lazy(() =>
      import("../../view/pages/settings/company/branch-list")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/add-branch",
    component: lazy(() =>
      import("../../view/pages/settings/company/add-branch")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/organization-structure",
    component: lazy(() =>
      import("../../view/pages/settings/company/organization-structure")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/job-level",
    component: lazy(() =>
      import("../../view/pages/settings/company/job-level")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/employment-status",
    component: lazy(() =>
      import("../../view/pages/settings/company/employment-status")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/contract-type",
    component: lazy(() =>
      import("../../view/pages/settings/company/type-contract")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/division",
    component: lazy(() =>
      import("../../view/pages/settings/organization/division")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/department",
    component: lazy(() =>
      import("../../view/pages/settings/organization/department")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/bank",
    component: lazy(() =>
      import("../../view/pages/settings/company/bank-list")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/role-management",
    component: lazy(() =>
      import("../../view/pages/settings/role-management/role-management")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/settings/archive",
    component: lazy(() => import("../../view/pages/settings/archive")),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
