// state redux
const INITIAL_STATE = {
  data: null,
};

// reduces redux
const attendanceReducer = (state = INITIAL_STATE, action) => {
  if (action.type === "GET_API") {
    return {
      ...state,
      data: action.value,
    };
  } else {
    return {
      ...state,
    };
  }
};

export { attendanceReducer };
