import React from "react";
import { Menu } from "antd";
import { useState } from "react";
import navigation from "../../../navigation/vertical";
import { useLocation, useHistory } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SubMenuTab() {
  // const [filterPage, setFilterPage] = useState({});
  const { pathname } = useLocation();
  const { push } = useHistory();
  const query = useQuery();

  const [activeKey, setActiveKey] = useState(pathname);
  const filterPage = navigation.find(
    (el) => el.navLink === (query.get("key") || pathname)
  );

  let management = window.localStorage.getItem("menu")
    ? JSON.parse(window.localStorage.getItem("menu"))
    : {};

  const recursiveData = (data) => {
    if (data.children !== undefined) {
      return (
        <Menu.SubMenu
          theme="dark"
          title={<div style={{ flex: 1, padding: "0 30px" }}>{data.title}</div>}
          onTitleClick={(e) => console.log("E", e)}
        >
          {data.children
            .filter((el) => {
              return (
                (management.role_management
                  ? management.role_management
                  : []
                ).filter((els) => {
                  if (el.title === els.menu) {
                    if (new RegExp("R").test(els.access)) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }).length > 0
              );
            })
            .map((item, index) => {
              if (item.children !== undefined) {
                return recursiveData(item);
              }
              return (
                <Menu.Item
                  key={item.navLink}
                  onClick={() => {
                    setActiveKey(item.navLink);
                    push(item.navLink + "?key=" + item.key);
                  }}
                >
                  <div
                    style={{
                      padding: "0 30px",
                      flex: 1,
                    }}
                  >
                    {item.title}
                  </div>
                </Menu.Item>
              );
            })}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item
          key={data.navLink}
          onClick={() => {
            setActiveKey(data.navLink);
            push(data.navLink + "?key=" + data.key);
          }}
        >
          <div
            style={{
              padding: "0 30px",
              flex: 1,
            }}
          >
            {data.title}
          </div>
        </Menu.Item>
      );
    }
  };

  if (
    typeof filterPage !== "undefined" &&
    typeof filterPage.children !== "undefined" &&
    filterPage.children.length > 0
  ) {
    return (
      <div className="sub-menu">
        <Menu mode="horizontal" activeKey={activeKey}>
          {filterPage.children
            .filter((el) => {
              return (
                (management.role_management
                  ? management.role_management
                  : []
                ).filter((els) => {
                  if (el.title === els.menu) {
                    if (new RegExp("R").test(els.access)) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }).length > 0
              );
            })
            .map((item) => {
              return recursiveData(item);
            })}
        </Menu>
      </div>
    );
  } else {
    return null;
  }
}
