import { Col, Layout, Row } from "antd";

export default function MenuFooter() {
  const { Footer } = Layout;

  return (
    <Footer
      className="hp-bg-color-black-10 hp-bg-color-dark-100"
      style={{
        marginTop: 50,
      }}
    >
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="hp-badge-text hp-mb-0 hp-text-color-dark-30">
            COPYRIGHT ©2022 GeekGarden, All rights Reserved
          </p>
        </Col>

        <Col
          md={12}
          span={24}
          className="hp-mt-sm-8 hp-text-sm-center hp-text-right"
        >
          <a
            href="#test"
            // target="_blank"
            className="hp-badge-text hp-text-color-dark-30"
          >
            🥁 Version: 1.0
          </a>
        </Col>
      </Row>
    </Footer>
  );
}
