import { createElement } from "react";
import { Col, Button } from "antd";
import { RiMenuUnfoldLine, RiMenuFoldLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

export default function HeaderText() {
  const navigation = useSelector((state) => state.navigation);
  const dispatch = useDispatch();
  const trigger = createElement(
    navigation.navigation_id ? RiMenuUnfoldLine : RiMenuFoldLine,
    {
      className: "trigger",
      onClick: () => {
        dispatch({ type: "NAVIGATION_ID", payload: !navigation.navigation_id });
      },
    },
  );
  return (
    <Col xl={16} lg={14} className="hp-header-left-text hp-d-flex-center">
      {/* <Document
        set="curved"
        size="large"
        className="remix-icon hp-update-icon hp-text-color-primary-1 hp-text-color-dark-0 hp-p-4 hp-bg-color-primary-4 hp-bg-color-dark-70"
      /> */}
      <Button
        icon={trigger}
        type="text"
        className="hp-float-right hp-text-color-dark-0"
      ></Button>
    </Col>
  );
}
