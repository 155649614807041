import { createContext } from "react";

// context management for web
const RootContext = createContext(null);

const ResignContext = createContext(null);
const TimeManagementContext = createContext(null);
const TrainingScheduleContext = createContext(null);
const TimeManagementAbsenceContext = createContext(null);
const TimeManagementNationalHolidayContext = createContext(null);

export {
  RootContext,
  ResignContext,
  TimeManagementContext,
  TrainingScheduleContext,
  TimeManagementAbsenceContext,
  TimeManagementNationalHolidayContext,
};
