import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import navigationReducer from "./navigation/navigationReducer";
import { attendanceReducer } from "./attendance/attendanceReducer";
import notificationReducer from "./notification/notificationReducer";
import { tokenReducer } from "./firebaseToken/tokenReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  navigation: navigationReducer,
  attendance: attendanceReducer,
  notification: notificationReducer,
  tokenReducer: tokenReducer,
});

export default rootReducer;
