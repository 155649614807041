import React, { useContext } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import SubMenuTab from "./components/submenu";
import { RootContext } from "../utils/context";

const { Content } = Layout;

export default function VerticalLayout(props) {
  const [visible, setVisible] = useContext(RootContext);
  const { children } = props;

  // const [visible, setVisible] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  return (
    <Layout className="hp-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />
      <Layout className="hp-bg-color-dark-90">
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 999,
            // marginBottom: 100,
          }}
        >
          <MenuHeader setVisible={setVisible} />
        </div>
        <Row
          justify="center"
          style={{
            marginTop: 100,
          }}
        >
          {customise.contentWidth === "full" && (
            <Col span={23}>
              <SubMenuTab />
            </Col>
          )}

          {customise.contentWidth === "boxed" && (
            <>
              <Col xxl={20} xl={22} span={23}>
                <SubMenuTab />
              </Col>
            </>
          )}
        </Row>

        <Content className="hp-content-main">
          <Row justify="center">
            {customise.contentWidth === "full" && (
              <Col span={24}>{children}</Col>
            )}

            {customise.contentWidth === "boxed" && (
              <Col xxl={20} xl={22} span={24}>
                {children}
              </Col>
            )}
          </Row>
        </Content>

        <MenuFooter />
      </Layout>

      <ScrollTop />
    </Layout>
  );
}
