// state redux
let INITIAL_STATE = {
  token: null,
};

// reducers redux
let tokenReducer = (state = INITIAL_STATE, action) => {
  if (action.type === "TOKEN_FIREBASE") {
    return {
      ...state,
      token: action.value,
    };
  }
  return state;
};

export { tokenReducer };
