import enLang from "./entries/en_US";
import frLang from "./entries/fr_FR";
import trLang from "./entries/tr_TR";
import esLang from "./entries/es_ES";
import idLang from "./entries/id_ID";

// multi language
const AppLocale = {
  en: enLang,
  fr: frLang,
  tr: trLang,
  es: esLang,
  id: idLang,
};

export default AppLocale;
