import React, { useState, createElement, useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";

import logoSmall from "../../../assets/images/logo/logo-small.svg";

// import MenuLogo from "./logo";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";

const { Sider } = Layout;

export default function Sidebar(props) {
  const navigation_id = useSelector((state) => state.navigation.navigation_id);
  const { visible, setVisible } = props;

  let management = window.localStorage.getItem("menu")
    ? JSON.parse(window.localStorage.getItem("menu"))
    : {};

  let check = (management.role_management
    ? management.role_management
    : []
  ).find((el) => {
    return el.menu === "Setting" && new RegExp("R").test(el.access);
  });

  // Redux
  const customise = useSelector((state) => state.customise);

  // Collapsed
  const [collapsed, setCollapsed] = useState(navigation_id);

  useEffect(() => {
    if (customise.sidebarCollapsed) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [customise]);

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
    className: "trigger",
    onClick: toggle,
  });

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={navigation_id ? navigation_id : collapsed}
      width={220}
      className="hp-sidebar hp-bg-color-black-0 hp-bg-color-dark-100"
    >
      <Row
        className="hp-mr-12 hp-ml-24 hp-mt-24"
        align="bottom"
        justify="space-between"
      >
        <Col></Col>

        {customise.sidebarCollapseButton && (
          <Col className="hp-pr-0">
            <Button
              icon={trigger}
              type="text"
              className="hp-float-right hp-text-color-dark-0"
            ></Button>
          </Col>
        )}

        {collapsed !== false && (
          <Col className="hp-mt-8">
            <Link to="/" onClick={onClose}>
              <img className="hp-logo" src={logoSmall} alt="logo" />
            </Link>
          </Col>
        )}
      </Row>

      <div
        style={{
          height: "calc(100vh - 180px)",
          overflowY: "scroll",
        }}
      >
        <MenuItem onClose={onClose} />
      </div>

      {check ? (
        new RegExp("R").test(check.access) ? (
          <MenuFooter onClose={onClose} collapsed={collapsed} />
        ) : null
      ) : null}

      <MenuMobile onClose={onClose} visible={visible} />
    </Sider>
  );
}
