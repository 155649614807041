import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ConfigProvider, notification } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "./languages";
import CryptoJS from "crypto-js";

import Router from "./router/Router";
import { RootContext } from "./utils/context";
import axios from "axios";
import { base_url } from "./configs/axios/axios";
import { useHistory } from "react-router-dom";
import Cryptr from "cryptr";
const cryptr = new Cryptr("myTotallySecretKey");

export default function App() {
  const { push } = useHistory();
  const customise = useSelector((state) => state.customise);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const currentAppLocale = AppLocale[customise.language];

  useEffect(() => {
    navigator.sayswho = (function () {
      var ua = navigator.userAgent;
      var tem;
      var M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(" ");
    })();

    document.querySelector("html").setAttribute("lang", customise.language);

    const browserName = navigator.sayswho.split(" ")[0];

    if (browserName !== "Safari") {
      const isSupported = require("firebase/messaging/sw");
      if (isSupported) {
        const getToken = require("firebase/messaging");
        const onMessage = require("firebase/messaging");
        const messaging = require("./utils/firebase");

        getToken
          .getToken(messaging.default)
          .then((currentToken) => {
            dispatch({ type: "TOKEN_FIREBASE", value: currentToken });
          })
          .catch((err) => {
            console.log(err);
          });

        onMessage.onMessage(messaging.default, (payload) => {
          dispatch({ type: "MESSAGE", value: payload });
          notification["info"]({
            message: payload.notification.title,
            description: payload.notification.body,
          });
        });
      }
    }
  }, [customise, dispatch]);

  if (window !== undefined && localStorage !== undefined) {
    // if (window.location.pathname !== "/login") {
    //   window.onbeforeunload = () => {
    //     window.onunload = () => {
    //       window.localStorage.removeItem("token");
    //     };
    //     return undefined;
    //   };
    // }
    const token =
      typeof window !== "undefined" ? localStorage.getItem("token") : null;
    if (token !== undefined && token !== null && token !== "") {
      axios.defaults.headers.common["token"] = token;
      axios.defaults.headers.common["Authorization"] =
        "Basic R0VFS0hSSVMyMDIyMDg6UzE1dDNtSDEySXNCWUczZWs=";
    }
    axios.defaults.baseURL = base_url;
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error && error.response) {
          let message = error.response.data.message || "Server Error";
          error.response.data.message = message;
          return Promise.reject(error);
        } else {
          let response = {
            status: 500,
            data: {
              message: "Network Error",
              status: 500,
            },
          };
          error.response = response;
          notification.error({
            message: "Network Error",
          });
          return Promise.reject(error);
        }
      }
    );

    if (token === null) {
      if (window.location.pathname !== "/reset-password") {
        push("/login");
      }
    }
  }

  console.error = () => {};

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={customise.direction}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <RootContext.Provider value={[visible, setVisible]}>
          <Router />
        </RootContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
}

/*eslint no-extend-native: ["error", { "exceptions": ["String","Number"] }]*/

String.prototype.dateData = function () {
  let day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return day[this];
};

String.prototype.monthDate = function () {
  let month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return month[this];
};

Number.prototype.formatMoney = function () {
  let formatter = new Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
  });

  let format = formatter.format(this);
  return format.replace(/Rp/g, "");
};

// reverse money from nominal with . to nominal
String.prototype.reverseMoney = function () {
  return (
    parseInt(
      this.replace(/[^\d\.\-]/g, "") // eslint-disable-line
        .split(".")
        .join("")
    ) / 100
  );
};

// encrypt nominal
String.prototype.encrypt = function () {
  return cryptr.decrypt(this);
};

// decrypt nominal
String.prototype.decrypt = function () {
  return CryptoJS.AES.decrypt(this, "HRIS-PIL-2022").toString(
    CryptoJS.enc.Utf8
  );
};

// format input money
String.prototype.formatInputMoney = function (label, precise) {
  let clearWordSymbol = this.replace(/[^0-9.]*/g, "");
  if (this.includes(".")) {
    const decimalCount = clearWordSymbol.split(".")[1].length;
    if (decimalCount > precise) {
      clearWordSymbol = Number.parseFloat(clearWordSymbol)
        .toFixed(precise)
        .toString();
    }
    const valSplit = clearWordSymbol.split(".");
    const beforeDot = valSplit[0];
    const afterDot = valSplit[1];
    const valWithComa = beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${label}${valWithComa}.${afterDot}`;
  } else {
    return `${label}${clearWordSymbol.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
};
