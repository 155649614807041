import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Button } from "antd";
// import { Calendar, Game, People } from "react-iconly";

import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import axios from "axios";
import { base_url } from "../../../configs/axios/axios";

export default function HeaderUser() {
  const [dataValue, setDataValue] = useState([]);

  const menu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12"
      style={{ width: 260 }}
    >
      {/* <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">
        Profile Settings
      </span>

      <Link
        to="/"
        className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2"
      >
        View Profile
      </Link>

      <Divider className="hp-mt-16 hp-mb-6" />

      <Row>
        <Col span={24}>
          <Link
            to="/"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <People set="curved" size={16} />

            <span className="hp-ml-8">Explore Creators</span>
          </Link>
        </Col>

        <Col span={24}>
          <Link
            to="/"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <Calendar set="curved" size={16} />

            <span className="hp-ml-8">Calendar Application</span>
          </Link>
        </Col>

        <Col span={24}>
          <Link
            to="/"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <Game set="curved" size={16} />

            <span className="hp-ml-8">Help Desk</span>
          </Link>
        </Col>
      </Row>

      <Divider className="hp-mb-16 hp-mt-6" /> */}

      <Link
        to="/login"
        className="hp-p1-body"
        onClick={async () => {
          await axios({
            method: "POST",
            url: "auth/logout",
            data: {
              key: window.localStorage.getItem("id"),
              device: "web",
            },
          }).then((res) => {
            if (res.data.status === 200) {
              window.localStorage.clear();
              window.location.href = "/login";
            }
          });
        }}
      >
        <Button
          type="text"
          block
          ghost
          style={{ display: "flex", justifyContent: "center" }}
          className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-fill-primary-1 hp-fill-dark-primary-2 hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-mt-4"
        >
          Logout
        </Button>
      </Link>
    </div>
  );

  const getData = useCallback(async () => {
    let employee_id;
    if (window) {
      employee_id = localStorage.getItem("employee_id");
    }
    await axios({
      method: "get",
      url: "admin/employee/get-one",
      params: {
        id: employee_id,
      },
    })
      .then((res) => {
        if (res.data.results) {
          setDataValue(res.data.results);
        }
      })
      .catch(async (err) => {
        if (err.response.status === 401) {
          await axios({
            method: "POST",
            url: "auth/logout",
            data: {
              key: window.localStorage.getItem("id"),
              device: "web",
            },
          }).then((res) => {
            if (res.data.status === 200) {
              window.localStorage.clear();
              window.location.href = "/login";
            }
          });
        }
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar
          src={
            dataValue
              ? dataValue.picture
                ? base_url + dataValue.picture
                : avatarImg
              : avatarImg
          }
          size={40}
          className="hp-cursor-pointer"
        />
      </Dropdown>
    </Col>
  );
}
