// const base_url = "http://194.31.53.214:3030/";
// const base_url = "http://localhost:3020/";
// const base_url = "http://192.168.8.230:3020/";
// const base_url = "https://hrisbe.geekgarden.id/";
// const base_url = "http://192.168.43.87:3020/";
// const base_url = "http://192.168.18.56:3020/"; //fawait
// const base_url = "http://192.168.1.41:3020";
// const base_url = "http://localhost:3020/";
// const base_url =git commit -m ""UPDATE "http://192.168.43.87:3020";
// const base_url = "http://192.168.43.7:3020/";
// const base_url = "http://10.113.73.14:3020/"; //bento kopi concat
// const base_url = "http://172.20.10.4:3020/";
// const base_url = "http://192.168.244.186:3020/";
// const base_url = "https://hrisbedev.geekgarden.id/";
// const base_url = "http://192.168.18.61:3020/";
// const base_url = "http://192.168.1.43:3020/";
const base_url = "https://hris.segitigamerah.com/";
// const base_url = "http://192.168.1.18:3020/";
// const base_url = "http://192.168.1.20:3020/";
// const base_url = "http://192.168.1.30:3020/";
// const base_url = "http://192.168.1.20:3020/";

// const base_url = "http://192.168.10.107:3020/";
// const base_url = "http://192.168.10.118:3020/";
// const base_url = "http://192.168.8.230:3020/";
// const base_url = "http://10.5.63.184:3020/";
// const base_url = "http://192.168.1.41:3020/";

//setting axios
export { base_url };
