import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import YodaRtl from "../../../../assets/images/logo/logo-rtl.svg";
import YodaRtlDark from "../../../../assets/images/logo/logo-rtl-dark.svg";
import HRISLogo from "../../../../assets/images/logo/logoHRIS.svg";

export default function MenuLogo(props) {
  const customise = useSelector((state) => state.customise);

  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-end">
      <Link to="/" onClick={props.onClose}>
        {customise.direction === "rtl" ? (
          customise.theme === "light" ? (
            <img className="hp-logo" src={YodaRtl} alt="logo" />
          ) : (
            <img className="hp-logo" src={YodaRtlDark} alt="logo" />
          )
        ) : customise.theme === "light" ? (
          <img className="hp-logo" src={HRISLogo} alt="logo" />
        ) : (
          <img className="hp-logo" src={HRISLogo} alt="logo" />
        )}
      </Link>
    </div>
  );
}
