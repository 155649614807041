const INITIAL_STATE = {
  message: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === "MESSAGE") {
    return {
      ...state,
      message: action.value,
    };
  }
  return state;
};

export default reducer;
