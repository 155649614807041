import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { switchLanguage } from "../../../redux/customise/customiseActions";

import { Menu, Avatar, Card, Col } from "antd";

// import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function HeaderLanguages() {
  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();

  // Language
  // const [activeLanguage, setActiveLanguage] = useState(customise.language);
  const languages = ["en", "id"];
  const selected = localStorage.getItem("i18nextLng") || "en";

  useEffect(() => {
    // setActiveLanguage(customise.language);
    i18next.changeLanguage(selected);
  }, [customise, selected]);

  // const { t } = useTranslation();

  return (
    <Col className="hp-languages hp-mr-sm-4 hp-mr-8">
      <div className="hp-d-flex-center">
        <Avatar
          size={24}
          src={
            require(`../../../assets/images/languages/${selected}.svg`).default
          }
        />
        <span className="hp-languages-text hp-text-color-black-60 hp-text-uppercase hp-ml-10">
          {selected}
        </span>
      </div>

      <div className="hp-languages-list">
        <Card className="hp-border-color-black-40">
          <Menu>
            {languages.map(
              (item, index) =>
                item !== selected && (
                  <Menu.Item
                    key={index}
                    onClick={() => {
                      dispatch(switchLanguage(item));
                      localStorage.setItem("i18nextLng", item);
                      i18next.changeLanguage(item);
                    }}
                  >
                    <div className="hp-d-flex-center">
                      <Avatar
                        size={24}
                        src={
                          require(`../../../assets/images/languages/${item}.svg`)
                            .default
                        }
                      />
                      <span className="hp-languages-text hp-text-color-black-60 hp-text-uppercase hp-ml-4">
                        {item}
                      </span>
                      {/* <div>{t("sidebar-pages")}</div> */}
                    </div>
                  </Menu.Item>
                )
            )}
          </Menu>
        </Card>
      </div>
    </Col>
  );
}
