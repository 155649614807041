import React from "react";
import { Link } from "react-router-dom";

import { Divider, Row, Col } from "antd";
import { RiSettings3Line } from "react-icons/ri";
import { useSelector } from "react-redux";

export default function MenuFooter(props) {
  const navigation = useSelector((state) => state.navigation);
  return navigation.navigation_id === false ? (
    <Row
      className="hp-sidebar-footer hp-pb-24 hp-px-24 hp-bg-color-dark-100"
      style={{
        marginTop: "40px",
        marginBottom: "-40px",
      }}
      align="middle"
      // justify="space-between"
    >
      <Divider className="hp-border-color-black-20 hp-border-color-dark-70 hp-mt-0" />

      <Row style={{ width: "100%" }}>
        <Link
          to="/settings/account-preferences?key=/settings"
          onClick={props.onClose}
          style={{ width: "100%" }}
        >
          <Row style={{ width: "100%" }}>
            <Col span={5}>
              <RiSettings3Line
                className="remix-icon hp-text-color-black-0 hp-text-color-dark-0"
                size={25}
              />
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "-2px",
                }}
              >
                {/* <Avatar size={36} src={avatar} className="hp-mr-8" /> */}

                {/* <div>
            <span className="hp-d-block hp-text-color-black-50 hp-text-color-dark-0 hp-p1-body">
              Setting
            </span>

            <Link
              to="#"
              className="hp-badge-text hp-text-color-dark-30"
              onClick={props.onClose}
            >
              <IntlMessages id="sidebar-view-profile" />
            </Link>
          </div> */}
                <span
                  className="hp-d-block hp-text-color-black-0 hp-text-color-dark-0 hp-p1-body"
                  style={{
                    fontSize: 16,
                    fontWeight: "400",
                    width: "100%",
                  }}
                >
                  Setting
                </span>
              </div>
            </Col>
          </Row>
        </Link>
      </Row>
    </Row>
  ) : (
    <Row
      className="hp-sidebar-footer hp-pt-16 hp-mb-16 hp-bg-color-dark-100"
      align="middle"
      justify="center"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Col>
        <Link
          to="/settings/account-preferences?key=/settings"
          // onClick={props.onClose}
        >
          {/* <Avatar size={36} src={avatar} />*/}
          <RiSettings3Line
            className="remix-icon hp-text-color-black-0"
            size={24}
          />
        </Link>
      </Col>
    </Row>
  );
}
