// import { CloseSquare } from "react-iconly";
import {
  AiOutlineFileDone,
  AiOutlineTeam,
  AiOutlineClockCircle,
  AiOutlineDollarCircle,
  AiOutlineFileText,
  AiOutlineSchedule,
  AiOutlineQrcode,
} from "react-icons/ai";

const pages = [
  // {
  //     header: <IntlMessages id="sidebar-pages" />,
  // },
  // {
  //   id: "dashboard",
  //   // title: <IntlMessages id="sidebar-pages-blank-page" />,
  //   title: "Dashboard",
  //   icon: <PaperPlus set="curved" className="remix-icon" />,
  //   navLink: "/",
  // },
  {
    id: "Attendance",
    title: "Attendance",
    icon: <AiOutlineFileDone />,
    heading: "Attendance Dashboard",
    navLink: "/attendance",
    children: [
      {
        navLink: "/attendance",
        title: "Report",
        key: "/attendance",
        children: [
          {
            navLink: "/attendance/report-attendance",
            title: "Report Attendance",
            key: "/attendance",
          },
          {
            navLink: "/attendance/report-overtime",
            title: "Report Overtime",
            key: "/attendance",
          },
        ],
      },
      {
        navLink: "/attendance/time-off-deduction",
        title: "Absense - Time Off Deduction",
        key: "/attendance",
      },
      {
        navLink: "/attendance/approval-time-off",
        title: "Approval Time Off",
        key: "/attendance",
      },
      {
        navLink: "/time-management/schedule",
        title: "Attendance Setting",
        key: "/time-management",
      },
    ],
  },
  {
    id: "reminder",
    title: "Reminder",
    show: false,
    icon: "",
    navLink: "/reminder",
    children: [
      {
        navLink: "/reminder",
        title: "Reminder",
        key: "/reminder",
      },
      {
        navLink: "/reminder/request-time-off",
        title: "Request Time Off",
        key: "/reminder",
      },
      {
        navLink: "/reminder/request-overtime",
        title: "Request Overtime",
        key: "/reminder",
      },
      {
        navLink: "/reminder/request-change-of-shift",
        title: "Request Change of Shift",
        key: "/reminder",
      },
      {
        navLink: "/reminder/request-change-profile",
        title: "Request Change Profile",
        key: "/reminder",
      },
      {
        navLink: "/reminder/request-assessment-management",
        title: "Request Assessment Management",
        key: "/reminder",
      },
    ],
  },
  {
    id: "Employee",
    title: "Employee",
    icon: <AiOutlineTeam />,
    navLink: "/pages/employee",
    // children: [],
  },
  {
    id: "Time Management",
    title: "Time Management",
    icon: <AiOutlineClockCircle />,
    navLink: "/time-management",
    children: [
      {
        navLink: "/time-management",
        title: "Master Time Off",
        key: "/time-management",
      },
      {
        navLink: "/time-management/schedule",
        title: "Master Schedule",
        key: "/time-management",
        children: [
          // {
          //   navLink: "/time-management/pattern",
          //   title: "Pattern",
          //   key: "/time-management",
          // },
          {
            navLink: "/time-management/schedule",
            title: "Schedule",
            key: "/time-management",
          },
          {
            navLink: "/time-management/attendance-machine",
            title: "Attendance Machine",
            key: "/time-management",
          },
        ],
      },
      {
        navLink: "/time-management/absence",
        title: "Master Absence",
        key: "/time-management",
      },
      {
        navLink: "/time-management/national-holiday",
        title: "Master National Holiday",
        key: "/time-management",
      },
      {
        navLink: "/time-management/setting",
        title: "Time Management Setting",
        key: "/time-management",
      },
    ],
  },
  {
    id: "Payroll",
    title: "Payroll",
    icon: <AiOutlineDollarCircle />,
    navLink: "/payroll",
    children: [
      {
        navLink: "/payroll",
        title: "Master Payroll",
        key: "/payroll",
      },
      {
        navLink: "/payroll/run-payroll",
        title: "Run Payroll",
        key: "/payroll",
      },
      {
        navLink: "/payroll/run-thr",
        title: "Run THR",
        key: "/payroll",
      },
      {
        navLink: "/payroll/report",
        title: "Report Payroll",
        key: "/payroll",
      },
      {
        navLink: "/payroll/setting",
        title: "Setting Payroll",
        key: "/payroll",
        children: [
          {
            navLink: "/payroll/setting/cut-off",
            title: "Cut Off",
            key: "/payroll",
          },
          {
            navLink: "/payroll/setting/pro-rate-settings",
            title: "Pro-Rate",
            key: "/payroll",
          },
          // {
          //   navLink: "/payroll/setting/absence",
          //   title: "Absence",
          //   key: "/payroll",
          // },
          {
            navLink: "/payroll/setting/thr",
            title: "THR Setting",
            key: "/payroll",
          },
        ],
      },
    ],
  },
  {
    id: "Matrix KPI",
    title: "Matrix KPI",
    icon: <AiOutlineFileText />,
    navLink: "/matrix-kpi",
    children: [
      {
        navLink: "/matrix-kpi/kpi-indicator",
        title: "KPI Indicator",
        key: "/matrix-kpi",
      },
      {
        navLink: "/matrix-kpi/assesment",
        title: "Assessment",
        key: "/matrix-kpi",
        children: [
          {
            navLink: "/matrix-kpi/assesment-hr",
            title: "Assessment HR",
            key: "/matrix-kpi",
          },
          {
            navLink: "/matrix-kpi/assesment-spv",
            title: "Assessment SPV",
            key: "/matrix-kpi",
          },
          // {
          //   navLink: "/matrix-kpi/assesment-management",
          //   title: "Assesment Management",
          //   key: "/matrix-kpi",
          // },
        ],
      },
      {
        navLink: "/matrix-kpi/result",
        title: "Result KPI",
        key: "/matrix-kpi",
      },
    ],
  },
  {
    id: "Training",
    title: "Training",
    icon: <AiOutlineSchedule />,
    navLink: "/training",
    children: [
      {
        navLink: "/training/schedule",
        title: "Schedule",
        key: "/training",
      },
      {
        navLink: "/training/course",
        title: "Course",
        key: "/training",
      },
      {
        navLink: "/training/report-of-quiz",
        title: "Report of Quiz",
        key: "/training",
      },
    ],
  },
  {
    id: "Food Coupon",
    title: "Food Coupon",
    icon: <AiOutlineQrcode />,
    navLink: "/food-coupon",
  },
  {
    id: "settings",
    title: "Setting",
    show: false,
    icon: "",
    navLink: "/settings",
    children: [
      {
        navLink: "/settings/account-preferences",
        title: "Account Preferences",
        key: "/settings",
        children: [
          {
            navLink: "/settings/account-preferences",
            title: "Profile Picture",
            key: "/settings",
          },
          {
            navLink: "/settings/change-password",
            title: "Change Password",
            key: "/settings",
          },
        ],
      },
      {
        navLink: "/settings/company",
        title: "Company",
        key: "/settings",
        children: [
          {
            navLink: "/settings/company",
            title: "Company Info",
            key: "/settings",
          },
          {
            navLink: "/settings/branch-list",
            title: "Branch List",
            key: "/settings",
          },
          {
            navLink: "/settings/add-branch",
            title: "Add Branch",
            key: "/settings",
          },

          {
            navLink: "/settings/employment-status",
            title: "Employment Status",
            key: "/settings",
          },
          {
            navLink: "/settings/contract-type",
            title: "Contract Type",
            key: "/settings",
          },
          {
            navLink: "/settings/bank",
            title: "Bank",
            key: "/settings",
          },
        ],
      },
      {
        navLink: "/settings/organization-structure",
        title: "Organization",
        key: "/settings",
        children: [
          {
            navLink: "/settings/department",
            title: "Department",
            key: "/settings",
          },
          {
            navLink: "/settings/division",
            title: "Division",
            key: "/settings",
          },
          {
            navLink: "/settings/job-level",
            title: "Job Level",
            key: "/settings",
          },
          {
            navLink: "/settings/organization-structure",
            title: "Organization Structure",
            key: "/settings",
          },
        ],
      },
      {
        navLink: "/settings/role-management",
        title: "Role Management",
        key: "/settings",
      },
      {
        navLink: "/settings/archive",
        title: "Archive",
        key: "/settings",
      },
    ],
  },
];

export default pages;
