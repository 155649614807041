import React, { useEffect, useState } from "react";

import {
  Calendar,
  Card,
  Col,
  Divider,
  Row,
  Tooltip,
  Modal,
  Input,
  Form,
  Cascader,
  Upload,
  notification,
  Button,
  List,
  Skeleton,
  Empty,
} from "antd";

import PageTitle from "../../layout/components/content/page-title";
import { useHistory } from "react-router-dom";

import {
  AiOutlineUsergroupAdd,
  AiOutlineUserSwitch,
  AiOutlineSound,
  AiFillSetting,
  // AiOutlineFileText,
  AiOutlinePaperClip,
} from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";

const primaryColor = "#0E2D51";

const dummyRemember = [];

for (let index = 0; index < 20; index++) {
  const obj = {
    title:
      index < 5
        ? "Today"
        : index > 5 && index <= 10
        ? "Yesterday"
        : index > 10
        ? "Ago"
        : "",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore",
  };
  dummyRemember.push(obj);
}

// const presentation = [
//   {
//     name: "Permanent",
//     count: 85,
//     persent: "26%",
//     color: "#FF8A8A",
//   },
//   {
//     name: "Contract",
//     count: 209,
//     persent: "64%",
//     color: "#98FB98",
//   },
//   {
//     name: "Training",
//     count: 30,
//     persent: "9%",
//     color: "#73C2FB",
//   },
//   {
//     name: "Intership",
//     count: 5,
//     persent: "1%",
//     color: "#E29FF6",
//   },
// ];

// const jobLevel = [
//   {
//     name: "Presiden Direktur",
//     count: 1,
//     persent: "0.3%",
//   },
//   {
//     name: "Wakil Direktur",
//     count: 1,
//     persent: "0.3%",
//   },
//   {
//     name: "Direktur",
//     count: 5,
//     persent: "1.5%",
//   },
//   {
//     name: "Manager",
//     count: 15,
//     persent: "4.5%",
//   },
//   {
//     name: "Supervisor",
//     count: 15,
//     persent: "4.5%",
//   },
//   {
//     name: "Staff",
//     count: 86,
//     persent: "26.1%",
//   },
//   {
//     name: "Operator",
//     count: 206,
//     persent: "62.8%",
//   },
// ];

// const whoOffdata = [
//   {
//     name: "Jajang",
//     position: "Staff Accounting",
//   },
//   {
//     name: "Didi",
//     position: "Staff Finance",
//   },
//   {
//     name: "Nenda",
//     position: "Supervisor PPIC",
//   },
//   {
//     name: "Adma",
//     position: "Operator Produksi",
//   },
// ];

const EmployeeStatus = ({ access }) => {
  const { push } = useHistory();

  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  // const colors = {
  //   permanent: "#FF8A8A",
  //   contract: "#98FB98",
  //   training: "#73C2FB",
  //   internship: "#E29FF6",
  // };
  const color = {
    0: "#FF8A8A", // pink
    1: "#98FB98", // green
    2: "#73C2FB", // blue
    3: "#E29FF6", // purple
    4: "#F40F0F", // red
    5: "#F1F40F", // yellow
    6: "#0FDFF4", //cyan
    7: "#F4820F", // orange
    8: "#1508E8", // dark blue
    9: "#7D6548", // brown
  };

  useEffect(() => {
    // let arr = [];
    // axios({
    //   method: "get",
    //   url: "admin/setting-employment-status",
    // }).then((res) => {
    //   let response = res.data.results.rows;
    //   arr = response;
    //   // console.log(response);
    // });

    axios.get("/dashboard/employee-status").then((response) => {
      const responseData =
        response.data.results.data.data || response.data.results.data;
      const dashboardData = responseData.map((item, index) => {
        return {
          ...item,
          color: color[index],
          field: item.field.charAt(0).toUpperCase() + item.field.slice(1),
        };
      });
      setTotal(response.data.results.data.total_employee || 0);
      setData(dashboardData);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading ? (
        <Card>
          <Skeleton />
        </Card>
      ) : (
        <Card
          className="hp-border-color-black-40 hp-border-color-dark-80"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (new RegExp("R").test(access)) {
              push("/pages/employee");
            }
          }}
        >
          <div
            style={{
              color: primaryColor,
              fontSize: 16,
              fontWeight: "700",
            }}
          >
            {t("dashboard.employment-status")}
          </div>
          <>
            {data.length ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: 50,
                    display: "flex",
                    marginTop: 20,
                  }}
                >
                  {data.map((item, i) => {
                    return (
                      <Tooltip key={i} title={`${item.field}: ${item.count}`}>
                        <div
                          style={{
                            width: item.count,
                            background: item.color,
                            height: "inherit",
                          }}
                        ></div>
                      </Tooltip>
                    );
                  })}
                </div>
                <Row
                  style={{
                    marginTop: 5,
                    marginBottom: 0,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {data.map((item, i) => {
                      return (
                        <div
                          style={{
                            fontSize: 9,
                            // color: item.color,
                            display: "flex",
                          }}
                          key={i}
                        >
                          <span
                            style={{
                              width: 6,
                              marginTop: 3,
                              marginBottom: "-3px",
                              marginRight: 3,
                              height: 6,
                              backgroundColor: item.color,
                              borderRadius: "50%",
                            }}
                          ></span>
                          <div>{item.field}</div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
                <Row
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Col span={8}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: 12,
                      }}
                    >
                      Total
                    </div>
                  </Col>
                  <Col span={13}></Col>
                  <Col span={3}>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: 12,
                        width: "inherit",
                        textAlign: "right",
                      }}
                    >
                      {total}
                    </div>
                  </Col>
                </Row>
                <Divider
                  style={{
                    marginTop: 0,
                  }}
                />
                <div
                  style={{
                    height: 80,
                    overflowY: "scroll",
                  }}
                >
                  {data.map((item, index) => {
                    return (
                      <Row
                        key={index}
                        style={
                          {
                            // marginTop: 20,
                          }
                        }
                      >
                        <Col span={8}>
                          <div
                            style={{
                              // fontWeight: "700",
                              fontSize: 12,
                            }}
                          >
                            {item.field}
                          </div>
                        </Col>
                        <Col span={13}>
                          <div
                            style={{
                              // fontWeight: "700",
                              fontSize: 12,
                              textAlign: "center",
                            }}
                          >
                            {Math.round(
                              (total * parseFloat(item.count.split("%")[0])) /
                                100
                            )}
                          </div>
                        </Col>
                        <Col span={3}>
                          <div
                            style={{
                              // fontWeight: "700",
                              fontSize: 12,
                              width: "inherit",
                              textAlign: "right",
                            }}
                          >
                            {item.count}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </>
            ) : (
              <Empty />
            )}
          </>
        </Card>
      )}
    </>
  );
};

const JobLevel = () => {
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get("/dashboard/job-level")
      .then((response) => {
        const responseData = response.data.results.data.rows;
        const groupedData = [];
        responseData.forEach((level) => {
          const existingLevelIndex = groupedData.findIndex(
            (el) => el.name === level.name
          );
          if (existingLevelIndex === -1) {
            groupedData.push({
              name: level.name,
              count: level.count,
            });
          } else {
            const currentCount = groupedData[existingLevelIndex].count;
            groupedData[existingLevelIndex].count = currentCount + level.count;
          }
        });
        setTotal(response.data.results.data.total);
        setData(groupedData);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <Card>
          <Skeleton />
        </Card>
      ) : (
        <Card className="hp-border-color-black-40">
          <div
            style={{
              color: primaryColor,
              fontSize: 16,
              fontWeight: "700",
            }}
          >
            {t("dashboard.job-level")}
          </div>
          <Row
            style={{
              marginTop: 20,
            }}
          >
            <Col span={8}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: 12,
                }}
              >
                Total
              </div>
            </Col>
            <Col span={13}></Col>
            <Col span={3}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: 12,
                  width: "inherit",
                  textAlign: "right",
                }}
              >
                {total}
              </div>
            </Col>
          </Row>
          <Divider
            style={{
              marginTop: 0,
            }}
          />
          {data.length ? (
            <div
              style={{
                height: 170,
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {data.map((item, i) => {
                return (
                  <Row
                    style={{
                      marginBottom: 5,
                    }}
                    key={i}
                  >
                    <Col span={10}>
                      <div
                        style={{
                          // fontWeight: "700",
                          fontSize: 12,
                        }}
                      >
                        {item.name}
                        {/* {item.company ? (
                          <>
                            {" "}
                            -{" "}
                            {item.company.length !== 0
                              ? item.company[0].division
                              : ""}
                          </>
                        ) : (
                          <></>
                        )} */}
                      </div>
                    </Col>
                    <Col span={11}>
                      <div
                        style={{
                          // fontWeight: "700",
                          fontSize: 12,
                          textAlign: "center",
                        }}
                      >
                        {item.count}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          // fontWeight: "700",
                          fontSize: 12,
                          width: "inherit",
                          textAlign: "right",
                        }}
                      >
                        {Math.round((item.count / total) * 100)}%
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <Empty />
          )}
        </Card>
      )}
    </>
  );
};

const WhoOff = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    axios.get("/dashboard/whoOff").then((response) => {
      setIsLoading(false);
      const result = response.data.results.data.rows;
      setData(
        result.map((item) => {
          return {
            name: item.employee !== null ? item.employee.full_name : "",
            position:
              item.employee !== null
                ? item.employee.company.length !== 0
                  ? item.employee.company[0].job_position
                  : ""
                : "",
            remark: item.comment,
          };
        })
      );
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Card>
          <Skeleton />
        </Card>
      ) : (
        <Card
          className="hp-border-color-black-40 hp-border-color-dark-80"
          style={{
            height: 963,
          }}
        >
          <div
            style={{
              color: primaryColor,
              fontSize: 16,
              fontWeight: "700",
            }}
          >
            {t("dashboard.whos-off")}
          </div>
          {data.length ? (
            <div
              style={{
                overflowX: "scroll",
                height: "inherit",
              }}
            >
              <Row
                style={{
                  marginTop: 20,
                  width: 400,
                }}
              >
                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: 12,
                    }}
                  >
                    {t("dashboard.column-name")}
                  </div>
                </Col>
                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: 12,
                      width: "inherit",
                      textAlign: "left",
                    }}
                  >
                    {t("dashboard.column-position")}
                  </div>
                </Col>
                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: 12,
                      width: "inherit",
                      textAlign: "left",
                    }}
                  >
                    {t("dashboard.column-remarks")}
                  </div>
                </Col>
              </Row>
              <Divider
                style={{
                  marginTop: 0,
                }}
              />
              {data.map((item, i) => {
                return (
                  <Row
                    style={{
                      marginBottom: 5,
                      width: 400,
                    }}
                    key={i}
                  >
                    <Col span={8}>
                      <div
                        style={{
                          // fontWeight: "700",
                          fontSize: 12,
                        }}
                      >
                        {item.name}
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          // fontWeight: "700",
                          fontSize: 12,
                          width: "inherit",
                          textAlign: "left",
                        }}
                      >
                        {item.position}
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          // fontWeight: "700",
                          fontSize: 12,
                          width: "inherit",
                          textAlign: "left",
                        }}
                      >
                        {item.remark}
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                overflowX: "scroll",
                height: "inherit",
              }}
            >
              <Empty style={{ marginTop: 24 }} />
            </div>
          )}
        </Card>
      )}
    </>
  );
};

const QuickLinks = ({
  accessAddEmployee,
  accessCompanyInfo,
  accessTransfer,
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const toAddEmployee = () => {
    push("/pages/add-employee");
  };

  const toEmployeeTransfer = () => {
    push("/pages/employee-transfer");
  };

  const toAnnouncement = () => {
    push("/reminder");
  };

  const toCompanySetting = () => {
    push("/settings/company?key=/settings");
  };

  useEffect(() => {
    getDataDepartment();
    getDataDivision();
  }, []);

  const getDataDepartment = async () => {
    await axios({
      method: "get",
      url: "global/department",
    }).then((res) => {
      setDataDepartment(res.data.results.rows);
    });
  };

  const getDataDivision = async () => {
    await axios({
      method: "get",
      url: "global/division",
    }).then((res) => {
      setDataDivision(res.data.results.rows);
    });
  };

  const [form] = Form.useForm();
  const [modalAdd, setModalAdd] = useState(false);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataDivision, setDataDivision] = useState([]);
  const [fileIsValid, setFileIsValid] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [originObj, setOriginObj] = useState(null);

  const handleNotification = (type, title, text) => {
    notification[type]({
      message: title,
      description: text,
    });
  };

  const cascaderValue = [
    {
      label: "All Employee",
      value: "employee",
    },
    {
      label: "Department",
      value: "department",
      children: dataDepartment.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Divisi",
      value: "division",
      children: dataDivision.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
  ];
  return (
    <>
      <Card className="hp-border-color-black-40">
        <div
          style={{
            color: primaryColor,
            fontSize: 16,
            fontWeight: "700",
          }}
        >
          {t("dashboard.quick-link")}
        </div>
        <Row
          style={{
            marginTop: 20,
          }}
        >
          <Col span={4}>
            <AiOutlineUsergroupAdd
              onClick={() =>
                new RegExp("R").test(accessAddEmployee) && toAddEmployee()
              }
              style={{
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </Col>
          <Col span={20}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                new RegExp("R").test(accessAddEmployee) && toAddEmployee()
              }
            >
              {t("dashboard.title-add-employee")}
            </span>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 10,
          }}
        >
          <Col span={4}>
            <AiOutlineUserSwitch
              onClick={() =>
                new RegExp("R").test(accessTransfer) && toEmployeeTransfer()
              }
              style={{
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </Col>
          <Col
            span={20}
            style={{ cursor: "pointer" }}
            onClick={() =>
              new RegExp("R").test(accessTransfer) && toEmployeeTransfer()
            }
          >
            {t("dashboard.title-transfer-employee")}
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 10,
          }}
        >
          <Col span={4}>
            <AiOutlineSound
              onClick={() => toAnnouncement()}
              style={{
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </Col>
          <Col
            span={20}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModalAdd(true);
              // toAnnouncement();
            }}
          >
            {t("dashboard.title-create-announcement")}
          </Col>
        </Row>
        <Row
          style={{
            marginTop: 10,
            cursor: "pointer",
          }}
        >
          <Col span={4}>
            <AiFillSetting
              onClick={() =>
                new RegExp("C").test(accessCompanyInfo) && toCompanySetting()
              }
              style={{
                fontSize: 20,
              }}
            />
          </Col>
          <Col
            span={20}
            style={{ cursor: "pointer" }}
            onClick={() =>
              new RegExp("C").test(accessCompanyInfo) && toCompanySetting()
            }
          >
            {t("dashboard.title-company-setting")}
          </Col>
        </Row>
      </Card>

      {/* Modal Add */}
      <Modal
        title="Post Announcement"
        centered
        open={modalAdd}
        confirmLoading={loadingModal}
        onOk={() => {
          form.validateFields().then(async (res) => {
            setLoadingModal(true);
            let post_to = {
              employee: undefined,
              department: undefined,
              division: undefined,
            };

            if (res.post_to) {
              res.post_to.map((el) => {
                if (el.length === 1) {
                  return (post_to[el[0]] = "all");
                } else {
                  return "";
                }
              });
              res.post_to
                .filter((el) => el.length === 2)
                .map((el) => {
                  if (post_to[el[0]]) {
                    return (post_to[el[0]] = [...post_to[el[0]], el[1]]);
                  } else {
                    return (post_to[el[0]] = [el[1]]);
                  }
                });
            }
            res.post_to = JSON.stringify(post_to);
            res.attachment = originObj || undefined;
            let formData = new FormData();
            Object.keys(res).map((item) => {
              return formData.append(item, res[item]);
            });
            let postData = await axios({
              method: "post",
              url: "admin/announcement",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: formData,
            });

            setLoadingModal(false);
            if (postData.status === 201 || postData.status === 200) {
              handleNotification(
                "success",
                "Success",
                "Data successfully added"
              );
              setModalAdd(false);
              form.resetFields();
              setOriginObj(null);
            } else {
              handleNotification("error", "error", "Data failed added");
            }
          });
        }}
        onCancel={() => {
          setModalAdd(false);
          form.resetFields();
          setOriginObj(null);
        }}
        okText="Post"
        cancelText={t("global.button.cancel")}
        width={589}
        bodyStyle={{
          padding: "0 24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <Form
            layout="vertical"
            name="basic"
            className="hp-mt-sm-16 hp-mt-32"
            form={form}
            initialValues={{ remember: true }}
          >
            <Row gutter={[20, 20]} justify="space-between">
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<div style={{ fontWeight: "600" }}>Subject</div>}
                      name="subject"
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<div style={{ fontWeight: "600" }}>Content</div>}
                      name="content"
                    >
                      <Input.TextArea rows={5} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<div style={{ fontWeight: "600" }}>Post To</div>}
                      name="post_to"
                    >
                      <Cascader
                        style={{
                          width: "100%",
                        }}
                        options={cascaderValue}
                        multiple
                        allowClear={false}
                        maxTagCount="responsive"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={{ fontWeight: "600", marginBottom: "10px" }}>
                      Add Attachment
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <Upload
                        multiple={false}
                        beforeUpload={(file) => {
                          if (
                            file.type !== "application/pdf" &&
                            file.type !== "image/jpeg" &&
                            file.type !== "image/png" &&
                            file.type !== "image/jpg"
                          ) {
                            handleNotification(
                              "error",
                              "Error",
                              "Format file harus jpg, png, jpeg, atau pdf"
                            );
                          } else if (file.size > 3000000) {
                            handleNotification(
                              "error",
                              "Error",
                              "File harus kurang dari 2MB"
                            );
                          } else {
                            setFileIsValid(true);
                          }
                        }}
                        onChange={(info) => {
                          if (fileIsValid === true) {
                            const nameFile = info.file.name;
                            setFileName(nameFile);
                            setFileIsValid(false);
                          }
                          setOriginObj(info.file.originFileObj);
                        }}
                        showUploadList={false}
                      >
                        <Button
                          ghost={true}
                          type="primary"
                          style={{ width: "fit-content" }}
                        >
                          Select File
                        </Button>
                        {/* <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                          boxShadow: "0px 8px 28px rgba(14, 31, 53, 0.08)",
                          background: "#ECECEC",
                          padding: "5px 10px",
                          marginBottom: "20px",
                          width: "fit-content",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineFileText />
                        <div style={{ fontWeight: "600" }}>Add Attachment</div> */}
                        {/* </div> */}
                      </Upload>
                      {/* Show filename after upload file */}
                      {fileName !== "" ? (
                        <div
                          style={{
                            border: "1px solid #e4e7ed",
                            borderRadius: "6px",
                            boxShadow: "0 4px 16px rgb",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            padding: "8px 20px",
                            width: "fit-content",
                          }}
                        >
                          <AiOutlinePaperClip />
                          {fileName}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const CalendarData = () => {
  const [dateModal, setDateModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("DD MMMM YYYY")
  );
  const [selectedEventList, setSelectedEventList] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    axios.get("/dashboard/calendar").then((response) => {
      const groupedEvent = [];
      response.data.results.data.forEach((res) => {
        const eventIndex = groupedEvent.length
          ? groupedEvent.findIndex((event) => event.date === res.date)
          : -1;

        if (eventIndex === -1) {
          const newEvent = {
            date: res.date,
            events: [res.name],
          };
          groupedEvent.push(newEvent);
        } else {
          groupedEvent[eventIndex].events.push(res.name);
        }
      });
      setAllEvents(groupedEvent);
    });
  }, []); // eslint-disable-line

  const dateCellRender = (value) => {
    const event = allEvents.find(
      (event) => event.date === value.format("YYYY-MM-DD")
    );
    if (event) {
      return (
        <p
          style={{
            width: "100%",
            overflow: "hidden",
            fontSize: 12,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginBottom: 0,
            marginTop: 4,
            opacity: 0.6,
          }}
        >
          {event.events[0]}
        </p>
      );
    }
    return <></>;
  };

  return (
    <>
      <style>
        {`
            .ant-picker-body {
              overflow: hidden
            }
            .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
              max-height: 64px;
            }

            .ant-picker-calendar-mode-switch {
              display: none
            }
          `}
      </style>
      <Card className="hp-border-color-black-40 calendar-data">
        <div>
          {(new Date().getMonth() + "").monthDate() +
            " " +
            new Date().getFullYear()}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Calendar
            fullscreen={false}
            headerRender={() => {
              return null;
            }}
            onChange={() => setDateModal(true)}
          />
        </div>
      </Card>
      <Modal
        title="Calendar"
        centered
        open={dateModal}
        footer={null}
        width={1024}
        onCancel={() => setDateModal(false)}
        bodyStyle={{ padding: 24 }}
      >
        <Calendar
          dateCellRender={dateCellRender}
          onSelect={(date) => {
            const isDateChange =
              selectedDate.slice(3) === date.format("MMMM YYYY");
            setSelectedDate(date.format("DD MMMM YYYY"));
            const events = allEvents.find(
              (event) => event.date === date.format("YYYY-MM-DD")
            )?.events;
            setSelectedEventList(events);
            setListModal(isDateChange);
          }}
        />
      </Modal>
      <Modal
        title={selectedDate}
        centered
        open={listModal}
        footer={null}
        onCancel={() => setListModal(false)}
        bodyStyle={{ padding: "12px 24px" }}
      >
        <List
          // bordered
          dataSource={selectedEventList}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Modal>
    </>
  );
};
const Reminder = () => {
  const { t } = useTranslation();
  const [ago, setAgo] = useState([]);
  const [yesterday, setYesterday] = useState([]);
  const [today, setToday] = useState([]);
  const [isLoadingReminder, setIsLoadingReminder] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      method: "get",
      url: "global/notification/dashboard",
      params: {
        employee_id: localStorage.getItem("employee_id"),
      },
    }).then((res) => {
      const arr = res.data.results.data;
      setAgo(arr.ago);
      setToday(arr.today);
      setYesterday(arr.yesterday);
      setIsLoadingReminder(false);
    });
  };

  return (
    <Card className="hp-border-color-black-40">
      <div
        style={{
          color: primaryColor,
          fontSize: 16,
          fontWeight: "700",
        }}
      >
        {t("dashboard.reminder")}
      </div>
      <div
        style={{
          marginTop: 20,
          height: 530,
          overflowY: "scroll",
        }}
      >
        {isLoadingReminder ? (
          <Card>
            <Skeleton />
          </Card>
        ) : today.length === 0 && yesterday.length === 0 && ago.length === 0 ? (
          <div
            style={{
              overflowX: "scroll",
              height: "inherit",
            }}
          >
            <Empty style={{ marginTop: 24 }} />
          </div>
        ) : (
          <>
            {today.length > 0 ? (
              <>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  Today
                </div>
                <Divider
                  style={{
                    marginTop: 0,
                  }}
                />
                {today.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        fontSize: 12,
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 3,
                          fontWeight: "700",
                        }}
                      >
                        #
                      </span>
                      <div>{item.message}</div>
                    </div>
                  );
                })}
              </>
            ) : null}
            {yesterday.length > 0 ? (
              <>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  Yesterday
                </div>
                <Divider
                  style={{
                    marginTop: 0,
                  }}
                />
                {yesterday.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        fontSize: 12,
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 3,
                          fontWeight: "700",
                        }}
                      >
                        #
                      </span>
                      <div>{item.message}</div>
                    </div>
                  );
                })}
              </>
            ) : null}
            {ago.length > 0 ? (
              <>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  Ago
                </div>
                <Divider
                  style={{
                    marginTop: 0,
                  }}
                />
                {ago.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        fontSize: 12,
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 3,
                          fontWeight: "700",
                        }}
                      >
                        #
                      </span>
                      <div>{item.message}</div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </>
        )}
      </div>
    </Card>
  );
};

export default function Home() {
  const [accessEmployement, setAccessEmployement] = useState("");
  const [accessTransfer, setAccessTransfer] = useState("");
  const [accessCompanyInfo, setAccessCompanyInfo] = useState("");
  const [accessAddEmployee, setAccessAddEmployee] = useState("");

  let management = JSON.parse(
    window.localStorage.getItem("menu")
      ? window.localStorage.getItem("menu")
      : {}
  );
  useEffect(() => {
    let managementCheck = checkAccessEmployement(management);
    setAccessEmployement((managementCheck && managementCheck.access) || "");
    let transferCheck = checkAccessTransfer(management);
    setAccessTransfer((transferCheck && transferCheck.access) || "");
    let companyCheck = checkAccessCompanyInfo(management);
    setAccessCompanyInfo((companyCheck && companyCheck.access) || "");
    let addEmployee = checkAccessAddEmployee(management);
    setAccessAddEmployee((addEmployee && addEmployee.access) || "");
  }, []); // eslint-disable-line

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <PageTitle
          pageTitle={`It's ${(
            new Date().getDay() + ""
          ).dateData()}, ${new Date().getDate()} ${(
            new Date().getMonth() + ""
          ).monthDate()} ${new Date().getFullYear()}`}
          color={primaryColor}
        />
      </Col>

      <Col xl={17} sm={17} md={17} span={24}>
        <Row gutter={[32, 32]}>
          <Col span={24} sm={24} xl={12} md={12}>
            <Row>
              <Col span={24}>
                <EmployeeStatus access={accessEmployement} />
              </Col>
            </Row>
          </Col>

          <Col span={24} sm={24} xl={12} md={12}>
            <Row>
              <Col span={24}>
                <JobLevel />
              </Col>
            </Row>
          </Col>
          <Col span={24} sm={24} xl={12} md={12}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <QuickLinks
                  accessTransfer={accessTransfer}
                  accessAddEmployee={accessAddEmployee}
                  accessCompanyInfo={accessCompanyInfo}
                />
              </Col>
              <Col span={24}>
                <CalendarData />
              </Col>
            </Row>
          </Col>
          <Col span={24} sm={24} xl={12} md={12}>
            <Row>
              <Col span={24}>
                <Reminder />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xl={7} sm={7} md={7} span={24}>
        <Row gutter={[32, 32]}>
          <Col span={24} sm={24} xl={24} md={24}>
            <Row>
              <Col span={24}>
                <WhoOff />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

let checkAccessEmployement = (payload) => {
  let management = payload.role_management ? payload.role_management : [];
  let findAccess = management.find((els) => {
    return "Employee" === els.menu;
  });

  return findAccess;
};

let checkAccessAddEmployee = (payload) => {
  let management = payload.role_management ? payload.role_management : [];
  let findAccess = management.find((els) => {
    return "Add Employee" === els.menu;
  });

  return findAccess;
};
let checkAccessTransfer = (payload) => {
  let management = payload.role_management ? payload.role_management : [];
  let findAccess = management.find((els) => {
    return "Employee Transfer" === els.menu;
  });

  return findAccess;
};
let checkAccessCompanyInfo = (payload) => {
  let management = payload.role_management ? payload.role_management : [];
  let findAccess = management.find((els) => {
    return "Company Info" === els.menu;
  });

  return findAccess;
};
