// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCcf6HnCQenW0o0FhwugOs9SaNMDmUNyfw",

  authDomain: "hris-pil-40551.firebaseapp.com",

  projectId: "hris-pil-40551",

  storageBucket: "hris-pil-40551.appspot.com",

  messagingSenderId: "968741496799",

  appId: "1:968741496799:web:1c101eed07e3af3718066a",

  measurementId: "G-FJ718HCN57",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

// const analytics = getAnalytics(app);

// import { getMessaging } from "firebase/messaging";
export default messaging;
